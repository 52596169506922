import React, { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as Roles from '../../resources/UserRole';
import * as cartActions from '../../actions/cartActions';
import ItemsSummary from './ItemsSummary';
import Notifications from '../../services/Notifications';
import CartService from '../../services/CartService';
import * as orderDocTypes from '../../helpers/OrderDocTypes';
import DateHelper from '../../helpers/DateHelper';
import { withSelectedCustomer } from '../../hooks/withSelectedCustomer';
import { withStorefrontConfig } from '../../hooks/StorefrontSettingsContext';
import { withCartConfig } from '../../hooks/CartConfigContext';

export class Cart extends React.Component {
    constructor(props) {
        super(props);

        const defaultFHC = this.props.StorefrontConfig.settingsList.DefaultFreightHandlingCode;

        this.state = {
            selectedBranch: null,
            errors: [],
            orderNumber: 0,
            orderIsQuote: false,
            placingOrder: false,
            paymentTerms: null,
            freightHandlingCode: props.freightHandlingCode || (props.selectedCustomer?.freightHandlingCode) || ((props?.freightHandlingCodes?.length || 0) > 0 ? props?.freightHandlingCodes[0]?.id : null) || defaultFHC,
            requestDate: null,
            isCashPayment: (props.selectedCustomer?.paymentTermId === props.StorefrontConfig.settingsList.CashPaymentTerm) ? true : false,
            branchVerificationItems: null

        };
        if (!(props && props.selectedCustomer && props.selectedCustomer.id)) {
            this.props.history.push({ pathname: '/' });
        }
    }

    componentDidMount() {
        this.mounted = true;
        if (this.props.currentUser.storefrontNumber === "6400") {
            let today = new Date();
            var minDate = DateHelper.subtract(today, { days: (this.props.CartConfig.transitDays * (-1)) });
            if (minDate > this.props.requestDate) {
                this.updateDate(minDate);
            }
        }
        else {
            this.updateDate(this.props.requestDate);
        }
    }

    loadCustomerDefaultPaymentTerms = () => {
        if (this.props.paymentTermId === null || this.props.paymentTermId === undefined || this.props.paymentTermId === "") {
            this.paymentTermsUpdated({ "paymentTermId": this.props.selectedCustomer.paymentTermId, "paymentInstrument": this.props.selectedCustomer.paymentInstrument });
        }
    }
    componentWillUnmount() {
        this.mounted = false;
    }

    allItemsAreAvailable() {
        if (this.props.fetching) {
            Notifications.message(this.props.CartConfig.labels.WaitForPriceAndAvailability)
            return false;
        }
        if (this.props.repriceMessageDisplayed) {
            Notifications.message(this.props.CartConfig.labels.UpateCartPriceMessage);
            return false;
        }

        for (let i = 0; i < this.props.items.length; i++) {
            let item = this.props.items[i];

            if (!item.availability) {
                Notifications.message(this.props.CartConfig.labels.WaitForPriceAndAvailability);
                return false;
            }

            if (item.availability === "Unavailable") {
                Notifications.error(this.props.CartConfig.labels.UnavailableItemsInCartMessage);
                return false;
            }

            if (item.quantity > item.quantityAvailable && item.product.stockingType === "U") {
                Notifications.error(this.props.CartConfig.labels.StockNotAvailableInCartMessage.replace("{0}", item.quantityAvailable).replace("{item}", item.product.sku));
                return false;
            }
        }
        return true;
    }

    fetchingCartPriceFinished = res => {
        if (res.items) {
            CartService.handleMultiplePriceAndAvailabilityResponse(res, this.props.cartActions);
        }
        else {
            Notifications.error(this.props.CartConfig.labels.FetchingPriceFailed);
        }
        this.setState({ fetching: false });
    }

    repriceCart = async () => {
        this.updateDate(this.props.requestDate);
        let emptyQuantityItems = this.props.items.filter((e) => e.quantity === "" || e.quantity === 0);
        let branchVerification;
        let emptyFreight = this.state.freightHandlingCode === null || this.state.freightHandlingCode === '0';
        if (emptyFreight && this.props.CartConfig.childComponents.FreightHandlingCodes.required === true) {
            return Notifications.error(this.props.CartConfig.labels.SelectFreightHandlingCode);
        }
        if (emptyQuantityItems.length > 0) {
            return Notifications.error(this.props.CartConfig.labels.QuantityRequired);
        }


        else {
            let branchItems = {
                branch: (this.state.selectedBranch && this.state.selectedBranch.length > 0) ? this.state.selectedBranch : this.props.selectedCustomer.branchPlant,
                skus: this.props.items.map((item) => {
                    return item.product.sku;
                })
            }
            if (this.props.CartConfig.settings.UseDirectBranchVerification) {
                branchVerification = await CartService.itemBranchVerification(branchItems);
                this.setState({ branchVerificationItems: branchVerification });
            }
            else {
                branchVerification = "";
            }
            if (branchVerification.length > 0) {
                return Notifications.error(
                    (this.props.StorefrontConfig.settingsList.UseCartBranchSelection !== '0'
                        ? this.props.CartConfig.labels.UnavailableAtSelectedBranchToast
                        : this.props.CartConfig.labels.UnavailableAtBranchToast)
                    + branchVerification);
            }
            else {
                let order = CartService.getSubmitOrderBodyEComm({
                    ...this.props,
                    docType: this.props.StorefrontConfig.settingsList.UseOrderType,
                    implementationId: this.props.StorefrontConfig.storefront.implementationId,
                    selectedCustomer: this.props.selectedCustomer,
                    useCustomerInventoryPreference: this.props.CartConfig.settings.UseCustomerInventoryPreference,
                    useBranchStockingType: this.props.CartConfig.settings.UseBranchStockingType,
                    storefrontNumber: this.props.currentUser.storefront.number,
                    selectedBranch: this.state.selectedBranch

                });
                this.setState({ fetching: true });
                return CartService.fetchPriceCartEComm(order).then(this.fetchingCartPriceFinished);
            }
        }

    }

    reviewIfItemsAvailable = () => {
        if (this.allItemsAreAvailable()) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            this.props.history.push({ pathname: '/cart/review', state: { orderIsQuote: false, dateFormat: this.props.StorefrontConfig.settingsList.DateFormat } });
        }
    }

    reviewQuote = () => {
        if (this.allItemsAreAvailable()) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            this.props.history.push({ pathname: '/cart/review', state: { orderIsQuote: true } });
        }
    }

    updateDate = (date) => {
        if (!date) {
            this.setState({ requestDate: null });
            return;
        }

        this.setState({ requestDate: date }, this.updateOrderHeader);

        if (this.isRepriceMessageDisplayed() && this.props.StorefrontConfig.storefrontSettingsLoaded && this.props.CartConfig.cartSettingsLoaded) {
            this.props.items.forEach((item) => {

                var d = new Date();
                var headerRequestDate = new Date(date);
                headerRequestDate.setHours(0, 0, 0, 0);
                d.setHours(0, 0, 0, 0);
                var daysForMType = this.props.StorefrontConfig.settingsList.NoOfDaysForMType === 'undefined' ? 0 : Number(this.props.StorefrontConfig.settingsList.NoOfDaysForMType);
                var daysForPType = this.props.StorefrontConfig.settingsList.NoOfDaysForPType === 'undefined' ? 0 : Number(this.props.StorefrontConfig.settingsList.NoOfDaysForPType);

                var chosenDate = (headerRequestDate > d) ? headerRequestDate : d;
                if (item.product.stockingType == 'M' && this.props.CartConfig.settings.UseStockingTypeForRequestDeliveryDate == true) {
                    chosenDate.setDate(chosenDate.getDate() + daysForMType);
                }
                else if (item.product.stockingType == 'P' && this.props.CartConfig.settings.UseStockingTypeForRequestDeliveryDate == true) {
                    chosenDate.setDate(chosenDate.getDate() + daysForPType);
                }

                chosenDate = DateHelper.subtract(chosenDate, { days: (this.props.CartConfig.transitDays * -1) });
                item.requestedDeliveryDate = chosenDate;
                this.updateCartItem(item);
            });
        }
    }

    updateRepriceMessageDisplayed = () => {
        if (!this.isRepriceMessageDisplayed()) {
            this.markRepriceMessageDisplayed();
            this.removeRepriceMessageDisplayedFromHistory();
        }
    }

    isRepriceMessageDisplayed = () => {
        const { location } = this.props;
        return location.state?.repriceMessageDisplayed ?? true;
    }

    markRepriceMessageDisplayed = () => {
        this.props.cartActions.UpdateRepriceMessageDisplayed();
    }

    removeRepriceMessageDisplayedFromHistory = () => {
        const { location, history } = this.props;

        const updatedState = { ...location.state };
        delete updatedState.repriceMessageDisplayed;

        history.replace({
            ...location,
            state: updatedState,
        });
    }

    freightHandlingCodeUpdate = (e) => {
        this.setState({ freightHandlingCode: e.target.value !== "" ? e.target.value : this.props.StorefrontConfig.settingsList.DefaultFreightHandlingCode }, this.updateOrderHeader);
    }

    paymentTermsUpdated = (payTerms) => {
        const paymentTerms = { "paymentTermId": payTerms.paymentTermId, "paymentInstrument": payTerms.paymentInstrument?.trim().length > 0 ? payTerms.paymentInstrument : this.props.selectedCustomer.paymentInstrument };
        this.setState({ paymentTerms: paymentTerms }, this.updateOrderHeader);
        this.cashPaymentUpdated(payTerms);
    }

    selectedBranchUpdate = (e) => {
        this.setState({ selectedBranch: e.target.value !== "" ? e.target.value : null }, this.updateOrderHeader);
    }

    cashPaymentUpdated = (payTerms) => {
        if (this.props.currentUser.roles.includes(Roles.CAN_SELECT_CASH_PAYMENT)) {
            if (payTerms.paymentTermId === this.props.StorefrontConfig.settingsList.CashPaymentTerm) {
                this.setState({ isCashPayment: true });
            }
            else {
                this.setState({ isCashPayment: false });
            }
        }
    }

    updateOrderHeader = () => {
        const paymentTerms = this.state.paymentTerms || { "paymentTermId": this.props.paymentTermId, "paymentInstrument": this.props.paymentInstrument };
        const freightHandlingCode = this.state.freightHandlingCode || this.props.freightHandlingCode;
        const requestDate = this.state.requestDate || this.props.requestDate;
        const selectedBranch = this.state.selectedBranch || this.props.selectedBranch;

        this.props.cartActions.UpdateOrderHeader(freightHandlingCode, requestDate, paymentTerms, selectedBranch);
        this.updateRepriceMessageDisplayed();
    }

    addItemToCart = (item, localized) => {
        this.props.cartActions.AddItemToCart(item, localized);
    }

    removeItem = (id) => {
        this.props.cartActions.RemoveCartItem(id, () => { Notifications.message(this.props.CartConfig.labels.OrderUpdated); });
    }

    clearCart = () => {
        const localized = this.props.CartConfig.labels;
        Notifications.confirmation(localized.ConfirmClearCartAllItems, () => {
            this.props.cartActions.ClearCart(
                this.props.currentUser.id,
                null, null, null, () => { Notifications.message(this.props.CartConfig.labels.OrderUpdated); }
            );
        });
    }

    updateCartItem = (item) => {
        this.props.cartActions.UpdateCartItem(item);
        this.forceUpdate();
    }

    updateLineItemQuantity = (item, quantity) => {
        CartService.updateQuantity(item, quantity).then((res) => {
            if (res) {
                this.updateCartItem(res);
                Notifications.message(this.props.CartConfig.labels.OrderUpdated)
            }
            else {
                Notifications.error(this.props.CartConfig.labels.UnabletoUpdateQuantity);
            }
        });
    }

    render() {
        const { fetching, isCashPayment, freightHandlingCode, branchVerificationItems, selectedBranch } = this.state;
        const { currentUser, paymentInstrument, paymentTermId, requestDate, selectedCustomer, repriceMessageDisplayed, subTotal, currency, itemCount, items } = this.props;
        const { DateFormat } = this.props.StorefrontConfig.settingsList;
        const branches = this.props.StorefrontConfig.branches;
        return (
            <ItemsSummary
                key={itemCount}
                branches={branches}
                selectedBranch={selectedBranch}
                editingQuote={false}
                selectedCustomer={selectedCustomer}
                currentUser={currentUser}
                items={items}
                branchVerificationItems={branchVerificationItems}
                freightHandlingCode={freightHandlingCode}
                paymentInstrument={paymentInstrument}
                paymentTermId={paymentTermId}
                requestDate={requestDate}
                dateFormat={DateFormat}
                repriceMessageDisplayed={repriceMessageDisplayed}
                fetching={fetching}
                subTotal={subTotal}
                currency={currency}
                updateLineItemQuantity={this.updateLineItemQuantity}
                updateCartItem={this.updateCartItem}
                removeItem={this.removeItem}
                addItemToCart={this.addItemToCart}
                updateOrderHeader={this.updateOrderHeader}
                paymentTermsUpdated={this.paymentTermsUpdated}
                freightHandlingCodeUpdate={this.freightHandlingCodeUpdate}
                selectedBranchUpdate={this.selectedBranchUpdate}
                updateDate={this.updateDate}
                reviewQuote={this.reviewQuote}
                reviewIfItemsAvailable={this.reviewIfItemsAvailable}
                repriceCart={this.repriceCart}
                localized={this.props.CartConfig.labels}
                loadCustomerDefaultPaymentTerms={this.loadCustomerDefaultPaymentTerms}
                isCashPayment={isCashPayment}
                clearCart={this.clearCart}
            />
        )
    }
}

Cart.propTypes = {
    cartActions: PropTypes.object,
    currentUser: PropTypes.object,
    fetching: PropTypes.bool,
    freightHandlingCode: PropTypes.string,
    items: PropTypes.array,
    paymentInstrument: PropTypes.string,
    paymentTermId: PropTypes.string,
    requestDate: PropTypes.object,
    selectedCustomer: PropTypes.object,
    subTotal: PropTypes.number,
    currency: PropTypes.string,
    itemCount: PropTypes.number,
    isCashPayment: PropTypes.bool,
    selectedBranch: PropTypes.string
};


function mapStateToProps(state) {
    return {
        currentUser: state.user.currentUser,
        fetching: state.cart.fetchingPricesAndAvailability.length !== 0,
        freightHandlingCode: state.cart.freightHandlingCode,
        items: state.cart.items,
        paymentInstrument: state.cart.paymentInstrument,
        paymentTermId: state.cart.paymentTermId,
        requestDate: state.cart.requestDate,
        selectedCustomer: state.user.selectedCustomer,
        repriceMessageDisplayed: state.cart.repriceMessageDisplayed,
        subTotal: state.cart.netSubtotal,
        currency: state.cart.netCurrency,
        itemCount: state.cart.items.length,
        selectedBranch: state.cart.selectedBranch
    };
}

function mapDispatchToProps(dispatch) {
    return {
        cartActions: bindActionCreators(cartActions, dispatch),
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withStorefrontConfig(withSelectedCustomer(withCartConfig(Cart)))));